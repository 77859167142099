import { NavLeft } from '../components/nav/NavLeft';
import { Card } from 'react-bootstrap';
 
export const LogoCard = ({msg='Get Ready To Connect With Your Favorite Podcasts'}) => {
    return(
      <div className="Middle">
        <NavLeft/> 
        <div className="Main" role="main">
          <div className="LeftRight">
            <div className="MainLeft">
                <Card className="cardContainer">
                  <div style={{textAlign: "center"}} className="formTitle">{msg}</div>
                  <img className="cardImgLg" src={'https://poditude.com/logo_512_512.png'} alt={'Poditude Logo'}/>
                  {/* <img className="cardImgLg" src={process.env.REACT_APP_IMAGE_URL + 'logo_500_500.png'} alt={'Poditude Logo'}/> */}
                </Card>
            </div>
          </div>
        </div>
      </div>
    )
}