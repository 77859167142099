import { lazy, Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postApi } from './state/thunkPostApi.js';
import { useOnlineStatus } from './hooks/useOnlineStatus';
import { monitorURL } from './state/configSlice.js';
import { amplifyConfig } from './amplifyConfig';
import { LogoCard } from "./views/LogoCard";
import { Header } from './layout/Header';
import { Footer } from './layout/Footer'; 
import * as Sentry from "@sentry/react";
import { sentryConfig } from '../src/sentryConfig';

//lazy loading for offline access 
const ViewSelector = lazy(() =>
  import('./views/ViewSelector.js').then((module) => ({ default: module.ViewSelector }))
);

const App = () => { 
  const dispatch = useDispatch();
  amplifyConfig();
  Sentry.init({...sentryConfig});
  const online = useOnlineStatus();
  
  //monitor url
  const href = useSelector((state) => state.config.href)
  useEffect(() => {    
    const interval = setInterval(() => {
      if(href !== window.location.href){
        dispatch(monitorURL())
      }
    }, 500);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, href]);

  //refresh overdue entities in state
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(postApi({path: '/refreshEntity', obj: []}))
     }, 90000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  const middleContent = () => {
    if(online === false || online === 'false'){
      return(<LogoCard msg={'Please Connect To the Internet'}/>)
    } else {
      return(
        <Suspense fallback={<LogoCard/>}>
          <ViewSelector />
        </Suspense>
      )
    }
  }
   
  return (
   <>
    <div className='AppMask'/>
    <div className='App'> 
        <Header/>
          <Sentry.ErrorBoundary fallback ={<LogoCard msg={'Please Close And Restart'} />}>
            {middleContent()}  
          </Sentry.ErrorBoundary>
        <Footer/> 
    </div>
   </> 
  );
  }

export default App;